import React from "react"

const GetStarted = () => {
  return (
    <section className="section">
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title">
              <h4 className="title mb-4">Ready to launch your business?</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                Have a chat with{" "}
                <b>SpacePilots</b>, and
                We know you will be over the moon.
              </p>

              <div className="mt-4 pt-2">
                <a href="/contactus" className="btn outline-secondary rounded">
                  LAUNCH
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetStarted

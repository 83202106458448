import React, { useState } from "react"
// import image from "../images/image 2.png"
import Typed from "react-typed"

const Hero = () => {
  const [launched, fireRocket] = useState(false)
  const handleLaunch = () => {
    fireRocket(true)
    setTimeout(() => {
      window.location.href = "/contactus"
    }, 1200)
  }
  return (
    <section className="bg-half-170 border-bottom d-table w-100" id="home">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-7">
            <div className="title-heading mt-4">
              {/* <div
                className="alert alert-light alert-pills"
                role="alert"
              >
                <span className="badge badge-secondary rounded mr-1">Coming Soon</span>
                <span className="content">
                  Join our pilot program{" "}
                  <span className="text-main">Learn More</span>
                </span>
              </div> */}
              <h1 className="heading mb-3 text-default">
                We take your
                <br />
                <Typed
                  className="text-main"
                  strings={[
                    "Website Design",
                    "Website Development",
                    "Website Hosting",
                    "Website Optimization",
                  ]}
                  typeSpeed={70}
                  backSpeed={50}
                  loop
                />
                <br />
                out of this world.
              </h1>
              <p className="para-desc text-muted">
                We know you will be over the moon.
              </p>
              <div className="mt-4">
                <div
                  className="btn outline-secondary rounded"
                  onClick={() => handleLaunch()}
                >
                  Launch your mission
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="position-relative">
              <img
                src={
                  "https://pngimage.net/wp-content/uploads/2018/06/spaceship-cartoon-png-5.png"
                }
                className={`img-fluid mx-auto d-block hero-image ${
                  launched ? "launch-hero" : ""
                }`}
                alt="spaceship"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

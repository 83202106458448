import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../containers/layout"

import Hero from "../components/hero"
import Services from "../components/services"
import Pricing from "../components/pricing"
import Marketing from "../components/marketing"
import GetStarted from "../components/getStarted"

const IndexPage = () => (
  <>
    <Helmet>
      <link href="/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/7515743.js"
      ></script>
      {/* <!-- Primary Meta Tags --> */}
      <title>SpacePilot - Out of this world development agency</title>
      <meta
        name="title"
        content="SpacePilot - Out of this world development agency"
      />
      <meta
        name="description"
        content="SpacePilot is a full service agency that can take you from the
    first twinkle of an idea to the bright shining star we know you
    can be."
      />

      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.spacepilot.io/" />
      <meta
        property="og:title"
        content="SpacePilot - Out of this world development agency"
      />
      <meta
        property="og:description"
        content="SpacePilot is a full service agency that can take you from the
    first twinkle of an idea to the bright shining star we know you
    can be."
      />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://www.spacepilot.io/" />
      <meta
        property="twitter:title"
        content="SpacePilot - Out of this world development agency"
      />
      <meta
        property="twitter:description"
        content="SpacePilot is a full service agency that can take you from the
    first twinkle of an idea to the bright shining star we know you
    can be."
      />
    </Helmet>
    <Layout>
      <Hero />
      <Services />
      <Pricing />
      <Marketing />
      <GetStarted />
    </Layout>
  </>
)

export default IndexPage

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const Marketing = () => {
  return (
    <section className="section border-bottom">
      <div className="container mt-100 mt-60">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-60">
              <h4 className="title mb-4">Marketing Services</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Our pilots will work with you to reach more clients, increase
                your conversion rate and capture more leads.
              </p>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <img
              src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-space-astronaut-cartoon-by-vexels.png"
              alt=""
              className="full-width mover"
            />
          </div>

          <div className="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ml-lg-5">
              <h4 className="title mb-4">Reach the stratosphere</h4>
              <ul className="list-unstyled feature-list text-muted">
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Specialists in paid search
                  and PPC
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Monthly performance reports
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheck} /> Daily optimization of
                  campaigns
                </li>
              </ul>
              <p className="text-muted">
                <b>Cost:</b>
              </p>
              <ul className="list-unstyled feature-list text-muted">
                <li>£999 of set up fee. (One off)</li>
                <li>10% of ad spend budget. (Monthly)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Marketing

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const Pricing = () => {
  return (
    <section className="section border-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-60">
              <h4 className="title mb-4">Website Packages</h4>
              <p className="text-muted para-desc mb-0 mx-auto">
                Here at <b>SpacePilot</b> everything we build is fully managed
                and hosted.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-md-4 col-12">
            <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded">
              <h2 className="title text-uppercase mb-4 text-center">UFO</h2>
              {/* <div className="d-flex justify-content-center mb-0 text-center">
                <span className="h4 mb-0 mt-2">£</span>
                <span className="price h1 mb-0">999</span>
              </div>
              <div className="d-flex justify-content-center mb-2 text-center"> 
              <small>+ £50/mo</small>
              </div> */}
              <div className="d-flex justify-content-center mb-4 text-center">
                <p>Web Design & Build</p>
              </div>
              <ul className="feature list-unstyled pl-0">
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Requirements gathering &
                  research
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Custom design
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> 1st month unlimited
                  revisions
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Hosting and maintenance
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Lead generating forms
                </li>
              </ul>
              <div className="d-flex justify-content-center text-center">
                <a href="/contactus" className="btn btn-primary mt-4">
                  Learn more
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded">
              <h2 className="title text-uppercase text-main mb-4 text-center">
                Rocket
              </h2>
              {/* <div className="d-flex justify-content-center mb-0 text-center">
                <span className="h4 mb-0 mt-2">£</span>
                <span className="price h1 mb-0">4999</span>
              </div>
              <div className="d-flex justify-content-center mb-2 text-center">
                <small>+ £50/mo</small>
              </div> */}
              <div className="d-flex justify-content-center mb-4 text-center">
                <p>eCommerce</p>
              </div>
              <ul className="feature list-unstyled pl-0">
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> eCommerce strategy
                  consulting
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> High converting PDP
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> 1st month unlimited
                  revisions
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Hosting and maintenance
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Lead generating forms
                </li>
              </ul>
              <div className="d-flex justify-content-center text-center">
                <a href="/contactus" className="btn btn-primary mt-4">
                  Learn more
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded">
              <h2 className="title text-uppercase mb-4 text-center">
                Space Station
              </h2>
              {/* <div className="d-flex justify-content-center mb-2 text-center">
                <span className="price h1 mb-0">Custom</span>
              </div> */}
              <div className="d-flex justify-content-center mb-4 text-center">
                <p>Custom Project</p>
              </div>
              <ul className="feature list-unstyled pl-0">
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Quick to market
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Strategy consulting
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Hosting and maintenance
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Professional UX & UI
                </li>
                <li className="feature-list">
                  <FontAwesomeIcon icon={faCheck} /> Highly scalable
                </li>
              </ul>
              <div className="d-flex justify-content-center text-center">
                <a href="/contactus" className="btn btn-primary mt-4">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row align-items-center">
          <div className="col-md-4 col-12">
            <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded">
              <div className="d-flex justify-content-center mb-2 text-center">
                <span className="h4 mb-0 mt-2">or £</span>
                <span className="price h1 mb-0">200</span>
                <span class="h4 align-self-end mb-1">/mo</span>
              </div>
              <div className="d-flex justify-content-center mb-2 text-center">
                <p>for 12 months</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="pricing-rates business-rate shadow bg-white pt-5 pb-5 p-4 rounded">
              <div className="d-flex justify-content-center mb-2 text-center">
                <span className="h4 mb-0 mt-2">£</span>
                <span className="price h1 mb-0">500</span>
                <span class="h4 align-self-end mb-1">/mo</span>
              </div>
              <div className="d-flex justify-content-center mb-2 text-center">
                <p>for 12 months</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="pricing-rates business-rate shadow bg-light pt-5 pb-5 p-4 rounded">
              <div className="d-flex justify-content-center mb-2 text-center">
                <span className="price h1 mb-0">Custom</span>
              </div>
              <div className="d-flex justify-content-center mb-2 text-center">
                <p>Finance packages available</p>
              </div>
            </div>
          </div> 
                  </div>*/}
      </div>
    </section>
  )
}

export default Pricing

import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSatellite,
  faRocket,
  faUserAstronaut,
  faMeteor,
  faSpaceShuttle,
  faMoon,
} from "@fortawesome/free-solid-svg-icons"

const Services = () => {
  return (
    <section className="section border-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">What We Do?</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                <b>SpacePilot</b> is a full service agency that can take you from the
                first twinkle of an idea to the bright shining star we know you
                can be.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-12">
            <div className="features mt-5">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faSatellite}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Strategy & Research</h4>
                <p className="text-muted mb-0">
                  We've planned many missions to outer space, we can guide you
                  through the asteroid fields to your destination.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faRocket}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Wireframe & Design</h4>
                <p className="text-muted mb-0">
                  You don't want your site to look like an alien. Our pilots
                  star gaze the latest trends to make sure you have a timeless
                  and functional design.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faUserAstronaut}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Development</h4>
                <p className="text-muted mb-0">
                  We've launched many missions to space and to us clean code,
                  modern tools and expert craftsmanship is essential to our
                  success.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faMoon}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Hosting & Maintenance</h4>
                <p className="text-muted mb-0">
                  You wouldn't leave your brand new spaceship to an amateur
                  would you? Neither would we! We run all our sites with a
                  CDN, Continuous deployment and HTTPS ensuring you won't have a
                  crash landing.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faSpaceShuttle}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Analytics & Optimization</h4>
                <p className="text-muted mb-0">
                  Data driven is our middle name... well not really but once
                  we've launched we monitor the performance with Google
                  Analytics and feed in those insights straight back to mission
                  control.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 mt-5">
            <div className="features">
              <div className="image position-relative d-inline-block">
                <FontAwesomeIcon
                  className="avatar avatar-small"
                  icon={faMeteor}
                />
              </div>

              <div className="content mt-4">
                <h4 className="title-2 text-main">Marketing</h4>
                <p className="text-muted mb-0">
                  Where is the fun in launching a new mission if nobody can see
                  it? Our marketing services are out of this world, making sure
                  your site is seen by the right people at the right time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
